import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import { TranslationContext } from '@utils/useTranslations';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Navigation, { NavSpacer } from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import StoriesGrid from '@components/StoriesGrid';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import * as styled from '../../components/SearchInstantPage/styles';
import Hit from '@components/SearchHit';
import StoriesGridWrapper from '@components/StoriesGrid/wrapper';
import StoriesCategoryHeader from '@components/StoriesGrid/categoryHeader';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

function StoriesCategoryPage(props) {
  const { data } = props;
  const rawData = {};

  const intl = useIntl();
  const locData = getLocalizedData(
    data,
    get(rawData, `sanityStoriesIndex.published_${intl.locale}`)
      ? intl.locale
      : 'en',
  );
  const pageData = locData.sanityStoriesIndex;
  const stories = get(locData, 'allSanityStory.nodes', []).map(node => ({
    ...node,
    date: new Date(node.publishedAt),
  }));

  const translationContext = {
    vi: get(rawData, 'sanityStoriesIndex.published_vi'),
    zh_Hans: get(rawData, 'sanityStoriesIndex.published_zh_Hans'),
  };

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={props.banner} navItems={props.navItems} />
        <main>
          <SEO
            description={get(pageData, 'route.openGraph.description')}
            image={get(pageData, 'route.openGraph.image.asset.fixed')}
            title={`${stories[0].category.title} - ${pageData.route.title}`}
            noIndex={pageData.isExcludedFromSearch}
          />
          <NavSpacer />
          <Space size="xsmall" />
          <Container centered hideOverflow>
            <InstantSearch searchClient={searchClient} indexName="main">
              {/*<styled.MhsSearchBox*/}
              {/*  defaultRefinement={''}*/}
              {/*  searchAsYouType={false}*/}
              {/*  showLoadingIndicator={true}*/}
              {/*  translations={{*/}
              {/*    placeholder: 'Enter search term here',*/}
              {/*  }}*/}
              {/*/>*/}
              <Configure
                filters={`type:story AND subtype.slug.current:${props.pageContext.slug}`}
                hitsPerPage={120}
                attributesToSnippet={['content']}
                analytics={false}
                enablePersonalization={false}
                distinct
              />
              <StoriesCategoryHeader categoryTitle={stories[0]?.category?.title} />
              <StoriesGridWrapper>
                <StoriesGrid
                  handleFilter={() => {
                    return false;
                  }}
                  stories={stories}
                />
              </StoriesGridWrapper>
              <styled.MhsInfiniteHits hitComponent={({hit}) => Hit({hit, suppressLabel: true})} />
            </InstantSearch>
          </Container>
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer showChat={pageData.showChat} />
      </TranslationContext.Provider>
    </>
  );
}

export const query = graphql`
  query StoriesQueryList($slug: String!) {
    allSanityStory(filter: {category: {slug: {current: {eq: $slug}}}}, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        _id
        category {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
        featuredQuote {
          en
          vi
          zh_Hans
          _type
        }
        excerpt {
          en
          vi
          zh_Hans
          _type
        }
        heroImageExtended {
          credit
          link
          route {
            title {
              _type
              en
              vi
              zh_Hans
            }
            slug {
              current
            }
          }
          altText {
            en
            vi
            zh_Hans
            _type
          }
          image  {
            asset {
              fluid(sizes: "(max-width: 600px) 100vw, 30vw", maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
              fixed(width: 1200) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        publishedAt
        preview
        title {
          en
          vi
          zh_Hans
          _type
        }
        slug {
          current
        }
      }
    }
    sanityStoriesIndex {
      showChat
      route {
        title {
          en
          vi
          zh_Hans
          _type
        }
        openGraph {
          title {
            en
            vi
            zh_Hans
            _type
          }
          description {
            en
            vi
            zh_Hans
            _type
          }
          image {
            asset {
              fixed(width: 1200) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
      published_vi
      published_zh_Hans
      isExcludedFromSearch
    }
  }
`;

export default StoriesCategoryPage;
