import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { useLocalFormat } from '@utils/localize';
import { Body, Heading } from '@components/type';
import * as styled from './styles';

function TextPreview({
  category,
  date,
  excerpt,
  title,
  selectCategory,
  slug,
  size,
  colorTheme,
  type,
}) {
  const localFormat = useLocalFormat();

  const layouts = {
    card:
      size === 'small' ? (
        <Heading level={300}>{title}</Heading>
      ) : (
        <>
          <styled.Heading level={300} noCaps>
            {title}
          </styled.Heading>
          <Body>{excerpt}</Body>
        </>
      ),
    column: <Heading levels={[400, 200]}>{title}</Heading>,
    sidebar: <Heading level={500}>{title}</Heading>,
  };

  return (
    <styled.Wrapper type={type}>
      <styled.Header size={size} type={type}>
        <styled.Caption  size={type === 'sidebar' ? 'small' : 'regular'} colorTheme={colorTheme}>
          {size !== 'small' && category && (
            <span onClick={() => selectCategory(category.slug.current)}>
              {category.title}
            </span>
          )}
          {date &&
            localFormat(new Date(date), 'LLL d, yyyy')}
        </styled.Caption>
      </styled.Header>
      <Link to={`/stories/${get(slug, 'current')}`} aria-label={title}>
        {layouts[type]}
      </Link>
    </styled.Wrapper>
  );
}

TextPreview.propTypes = {
  excerpt: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.oneOf(['card', 'column', 'sidebar']),
};

export default TextPreview;
