import React from 'react';
import get from 'lodash/get';

import propsTransformer from './propsTransformer';
import getCTAProps from '@utils/cta';
import getFluidImage from '@utils/fluidImage';
import Button from '@components/Button';
import AudioBlock from '@components/block/Audio';
import Carousel from '@components/block/Carousel';
import ContactBlock from '@components/block/Contact';
import ContentCarousel from '@components/block/ContentCarousel';
import CourseCarousel from '@components/block/CourseCarousel';
import Container from '@components/Container';
import DonationForm from '@components/DonationForm';
import DonationItemsForm from '@components/DonationItemsForm';
import EmbedBlock from '@components/block/Embed';
import FullWidthImageBlock from '@components/block/FullWidthImage';
import HorizonPicker from '@components/HorizonPicker';
import ImageBlock from '@components/block/Image';
import DoubleImageBlock from '@components/block/DoubleImage';
import Instagram from '@components/Instagram';
import MapBlock from '@components/block/Map';
import MediaGalleryPreview from '@components/MediaGallery/Preview';
import MembersBlock from '@components/block/Members';
import PageBlock from '@components/block/Page';
import ChatBlock from '@components/block/Chat';
import ScheduleBlock from '@components/block/Schedule';
import Space from '@components/Space';
import StaffBlock from '@components/block/Staff';
import StoriesBlock from '@components/block/Stories';
import StudentStories from '@components/StudentStories';
import Testimonial from '@components/block/Testimonial';
import TuitionCalculator from '@components/TuitionCalculator';
import TextBlock from '@components/block/Text';
import VideoEmbedBlock from '@components/block/VideoEmbed';
import UpNext from '@components/block/UpNext';
import FAQ from '@components/FAQ';
import SocialMediaEmbed from '@components/SocialMediaEmbed';

export default function(block, parent, lang) {
  if (!block) {
    return null;
  }
  block = propsTransformer(block);

  switch (block._type) {
    case 'audioBlock': {
      const file = {
        en: block.file,
        vi: block.viFile,
        zh_Hans: block.zh_HansFile,
      };

      const translatedBlock = {
        ...block,
        file: file[lang] || file.en,
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <AudioBlock key={block._key} {...translatedBlock} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'carousel': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <Carousel key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'contactBlock': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <ContactBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'contentCarousel': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <ContentCarousel key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'courseCarousel': {
      const props = {
        ...block,
        items: Array.isArray(block.items)
          ? block.items.map((item) => ({
            ...item,
            image: {
              asset: {
                fluid: getFluidImage(item.imageExtended.image, { sizes: '(max-width: 600px) 100vw, 50vw' }),
              },
            },
          }))
          : [],
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <CourseCarousel key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'cta': {
      return (
        <>
          <Container centered columns={[12, 5]}>
            <div style={{ textAlign: 'center' }}>
              <Button appearance={block.theme} fill {...getCTAProps(block)}>
                {block.label}
              </Button>
            </div>
          </Container>
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'donationBlock': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <DonationForm key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'donationItemsBlock': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <DonationItemsForm key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'embedBlock': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <EmbedBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'fullWidthImageBlock': {
      const props = {
        ...block,
        image: {
          asset: {
            fluid: getFluidImage(block.imageExtended.image, { sizes: '(max-width: 600px) 100vw, 50vw' }),
          },
        },
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <FullWidthImageBlock key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'gallery': {
      const props = {
        ...block,
        // images: get(block, 'images', []).map(({ image }) => ({
        //   height: get(image, 'asset.metadata.dimensions.height'),
        //   src: get(image, 'asset.url'),
        //   width: get(image, 'asset.metadata.dimensions.width'),
        // })),
        images: get(block, 'imagesExtended', []).map(({ altText, image }) => {
          const width = 600;
          const aspectRatio = get(image, 'asset.metadata.dimensions.aspectRatio');
          const height = parseInt(width / aspectRatio);

          return {
            height, width,
            src: `${get(image, 'asset.url')}?w=${width}&h=${height}`,
            alt: altText,
          };
        }),
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <MediaGalleryPreview key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'horizonPicker': {
      const placeholders = {
        en: block.placeholders,
        vi: block.viPlaceholders,
        zh_Hans: block.zh_HansPlaceholders,
      };

      const translatedBlock = {
        ...block,
        placeholders: placeholders[lang] || placeholders.en,
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <HorizonPicker key={block._key} {...translatedBlock} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'imageExtended': {
      const props = {
        ...block,
        image: {
          asset: {
            fluid: getFluidImage(block.image, { sizes: '(max-width: 600px) 100vw, 30vw' }),
          },
        },
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <ImageBlock key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'doubleImageBlock': {
      const props = {
        ...block,
        image1: {
          asset: {
            fluid: getFluidImage(block.imageExtended1.image, { sizes: '(max-width: 600px) 100vw, 30vw' }),
          },
        },
        image2: {
          asset: {
            fluid: getFluidImage(block.imageExtended2.image, { sizes: '(max-width: 600px) 100vw, 30vw' }),
          },
        },
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <DoubleImageBlock key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'instagramBlock': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <Instagram key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'map':
      return (
        <>
          {/*<Space size="small" />*/}
          <MapBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'membersBlock':
      return (
        <>
          {/*<Space size="small" />*/}
          <MembersBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'pageBlock': {
      const props = {
        ...block,
        imagesExtended: block.imagesExtended.map((image) => ({
          ...image,
          asset: {
            fluid: getFluidImage(image.image, {
              sizes: '(max-width: 600px) 100vw, 50vw',
            }),
          },
        })),
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <PageBlock key={block.key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'chatBlock': {
      const props = {
        ...block,
        image: {
          asset: {
            fluid: getFluidImage(block.imageExtended.image, {
              sizes: '(max-width: 600px) 100vw, 50vw',
            }),
          },
        },
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <ChatBlock key={block.key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'scheduleBlock': {
      const getDescription = (item, lang) => {
        switch (lang) {
          case 'zh_Hans':
            return item['zh_HansDescription'];
          case 'vi':
            return item['viDescription'];
          default:
            return item['description'];
        }
      };
      const items = block.items.map(item => ({
        ...item,
        description: getDescription(item, lang),
      }));

      return (
        <>
          <ScheduleBlock key={block._key} {...block} items={items} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'staffBlock':
      return (
        <>
          {/*<Space size="small" />*/}
          <StaffBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'storiesBlock':
      return (
        <>
          {/*<Space size="small" />*/}
          <StoriesBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'studentStories':
      const props = {
        ...block,
        items: Array.isArray(block.items)
          ? block.items.map((item) => ({
            ...item,
            student: {
              ...item.student,
              image: {
                asset: {
                  fluid: getFluidImage(get(item, 'student.image'), {
                    sizes: '(max-width: 600px) 100vw, 20vw',
                  }),
                },
              },
            },
          }))
          : [],
      };

      return (
        <>
          <StudentStories key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'textBlock': {
      const text = {
        en: block.text,
        zh_Hans: block.zh_HansText,
        vi: block.viText,
      };

      return (
        <>
          <TextBlock
            key={block._key}
            parent={parent}
            sidebar={block.sidebar}
            anchorName={block.anchorName}
            text={text[lang] || text.en}
            lang={lang}
          />
          {/*<Space sizes={['xsmall', 'small']} />*/}
        </>

      );
    }
    case 'testimonial': {
      const props = {
        ...block,
        locale: lang,
        image: {
          asset: {
            fluid: getFluidImage(block.imageExtended?.image, {
              sizes: '(max-width: 600px) 20vw, 20vw',
            }),
          },
        },
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <Testimonial key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'tuitionCalculator':
      return (
        <>
          {/*<Space sizes={['xsmall', 'small', 'small']} />*/}
          <TuitionCalculator key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    case 'upNext': {
      const props = {
        ...block,
        items: Array.isArray(block.items)
          ? block.items.map((item) => ({
            ...item,
            image: {
              asset: {
                fluid: getFluidImage(item.imageExtended.image, { sizes: '(max-width: 600px) 100vw, 30vw' }),
              },
            },
          }))
          : [],
      };

      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <UpNext key={block._key} {...props} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'videoEmbed': {
      return (
        <>
          {/*<Space sizes={['xsmall', 'small']} />*/}
          <VideoEmbedBlock key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'faqBlock': {
      return (
        <>
          <FAQ key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    case 'socialMediaEmbed': {
      return (
        <>
          <SocialMediaEmbed key={block._key} {...block} />
          <Space sizes={['xsmall', 'small']} />
        </>
      );
    }
    default:
      return null;
  }
}
