import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Heading as UnstyledHeading } from '@components/type';

export const Body = styled.div`
  display: flex;
  padding: 32px ${H_PADDING_MOBILE} 40px;
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};
  overflow-x: scroll;

  & > div {
    flex: 1 0 80vw;
    padding-right: 16px;

    &:last-of-type {
      border-right: 3px solid transparent;
    }

    &:not(:last-of-type) {
      margin-right: 16px;
      border-right: 3px solid ${(props) =>
        getThemeStyles(props.colorTheme).border};
    }
  }

  ${mq.gtmd`
    overflow: initial;
    padding: 56px 80px ${(props) => (props.size === 'small' ? '40px' : '56px')};

    & > div {
      flex: 1 1 0;
      width: 0;
    }
  `}
`;

export const Footer = styled.div`
  position: relative;
  padding: 0 ${H_PADDING_MOBILE};
  border-top: 24px solid ${(props) => getThemeStyles(props.colorTheme).background};

  & > svg:last-of-type {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;
  }

  & > *:not(svg) {
    transform: translateY(-24px);
    z-index: 1;
  }

  ${mq.gtsm`
    padding: 0 15vw;
    border-top: 0;

    & > *:not(svg) {
      transform: none;
    }
  `}
`;

export const Header = styled.div`
  position: relative;
  padding: 0 ${H_PADDING_MOBILE};

  & > svg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    vertical-align: bottom;
  }

  ${mq.gtsm`
    padding: 0 15vw;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  position: relative;
  z-index: 1;
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  text-align: ${(props) => props.align};
`;

export const Spacer = styled.span`
  display: block;
  flex: 1 0 calc(${H_PADDING_MOBILE} - 16px - 3px); /* card padding and border width */;
  width: calc(${H_PADDING_MOBILE} - 16px - 3px);

  ${mq.gtsm`
    display: none;
  `}
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        border: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        border: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        border: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        border: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        border: colors.Y200,
        text: colors.B500,
      };
  }
}
