import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import { TranslationContext } from '@utils/useTranslations';
import Contact from '@components/Contact';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Navigation from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import propsTransformer from '../utils/propsTransformer';
import blockFactory from '@utils/blockFactory';

function HomePage({ banner, navItems }) {
  const rawData = useStaticQuery(graphql`
    query HomeQuery {
      sanityHome {
        route {
          title {
            en
            vi
            zh_Hans
            _type
          }
          openGraph {
            description {
              en
              vi
              zh_Hans
              _type
            }
            keywords {
              en
              vi
              zh_Hans
              _type
            }
            image {
              asset {
                fixed(width: 1200) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
        published_vi
        published_zh_Hans
        hero {
          button {
            label {
              en
              vi
              zh_Hans
              _type
            }
            link
            route {
              slug {
                current
              }
            }
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          headingAlignment
          description {
            en
            vi
            zh_Hans
            _type
          }
          imagesExtended {
            credit
            link
            route {
              title {
                _type
                en
                vi
                zh_Hans
              }
              slug {
                current
              }
            }
            altText {
              en
              vi
              zh_Hans
              _type
            }
            image  {
              asset {
                fluid(
                  maxWidth: 800,
                  sizes: "(max-width: 600px) 100vw, 50vw"
                  ){
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          imagesFlipped
          theme
        }
        showChat
        studentStories {
          heading {
            en
            vi
            zh_Hans
            _type
          }
          type
          items {
            _key
            question {
              en
              vi
              zh_Hans
              _type
            }
            student {
              name
              image {
                asset {
                  fluid(sizes: "(max-width: 600px) 100vw, 30vw") {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            video {
              asset {
                url
              }
            }
          }
        }
        courseCarousel {
          button {
            label {
              en
              vi
              zh_Hans
              _type
            }
            link
            route {
              slug {
                current
              }
            }
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          items {
            _key
            description {
              en
              vi
              zh_Hans
              _type
            }
            eyebrow {
              en
              vi
              zh_Hans
              _type
            }
            heading {
              en
              vi
              zh_Hans
              _type
            }
            image {
              asset {
                fluid(sizes: "(max-width: 600px) 100vw, 40vw") {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          theme
        }
        contentCarousel {
          button {
            label {
              en
              vi
              zh_Hans
              _type
            }
            link
            route {
              slug {
                current
              }
            }
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          description {
            en
            vi
            zh_Hans
            _type
          }
          contentType
          items {
            polaroid {
              _key
              caption {
                en
                vi
                zh_Hans
                _type
              }
              heading {
                en
                vi
                zh_Hans
                _type
              }
              image {
                asset {
                  fluid(
                    maxWidth: 600,
                    sizes: "(max-width: 600px) 100vw, 30vw"
                  ){
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            quote {
              _key
              name
              quote {
                en
                vi
                zh_Hans
                _type
              }
              title {
                en
                vi
                zh_Hans
                _type
              }
              image {
                asset {
                  fluid(maxWidth: 1200) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            value {
              _key
              description {
                en
                vi
                zh_Hans
                _type
              }
              heading {
                en
                vi
                zh_Hans
                _type
              }
            }
          }
          theme
        }
        contact {
          isHidden
          description {
            en
            vi
            zh_Hans
            _type
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          person {
            image {
              asset {
                fluid(sizes: "(max-width: 600px) 50vw, 30vw") {
                  ...GatsbySanityImageFluid
                }
              }
            }
            name
            title {
              en
              vi
              zh_Hans
              _type
            }
          }
        }
        featuredPage {
          button {
            label {
              en
              vi
              zh_Hans
              _type
            }
            link
            route {
              slug {
                current
              }
            }
          }
          description {
            en
            vi
            zh_Hans
            _type
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          images {
            asset {
              fluid(sizes: "(max-width: 600px) 100vw, 50vw") {
                ...GatsbySanityImageFluid
              }
            }
            credit
          }
          imagesFlipped
          outline
          theme
        }
      _rawContent(resolveReferences: { maxDepth: 12 })
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `sanityHome.published_${intl.locale}`) ? intl.locale : 'en',
  );
  const pageData = get(data, 'sanityHome', {});

  const translationContext = {
    vi: get(rawData, 'sanityHome.published_vi'),
    zh_Hans: get(rawData, 'sanityHome.published_zh_Hans'),
  };

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={banner} navItems={navItems} />
        <main>
          <SEO
            description={get(pageData, 'route.openGraph.description')}
            image={get(pageData, 'route.openGraph.image.asset.fixed')}
            title={pageData.route.title}
            keywords={pageData.route.openGraph?.keywords}
          />
          {pageData.hero && (
            <>
              <Hero {...propsTransformer(pageData.hero)} />
              <Space sizes={['xsmall', 'small']} />
            </>
          )}
          {(pageData._rawContent || []).map((block, idx) => (
            <Fragment key={idx}>
              {blockFactory(block, null, intl.locale)}
            </Fragment>
          ))}
          {pageData.contact && !pageData.contact.isHidden && (
            <>
              <Contact {...propsTransformer(pageData.contact)} />
              <Space sizes={['xsmall', 'small']} />
            </>
          )}
        </main>
        {/*<Space sizes={['xsmall', 'small']} />*/}
        <Footer showChat={pageData.showChat} />
      </TranslationContext.Provider>
    </>
  );
}

export default HomePage;
