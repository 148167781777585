import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getLocalizedData from '@utils/localize';
import { TranslationContext } from '@utils/useTranslations';
import Footer from '@components/Footer';
import Hero from '@components/Hero';
import Navigation from '@components/Navigation';
import SEO from '@components/SEO';
import Space from '@components/Space';
import Timeline from '@components/Timeline/Timeline';
import UpNext from '@components/block/UpNext';
import propsTransformer from '../../utils/propsTransformer';

function DailyLifePage({ banner, navItems }) {
  const rawData = useStaticQuery(graphql`
    query DailyLifeQuery {
      sanityDailyLife {
        route {
          title {
            en
            vi
            zh_Hans
            _type
          }
          openGraph {
            keywords {
              en
              vi
              zh_Hans
              _type
            }
            description {
              en
              vi
              zh_Hans
              _type
            }
            image {
              asset {
                fixed(width: 1200) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
        published_vi
        published_zh_Hans
        hero {
          button {
            label {
              en
              vi
              zh_Hans
              _type
            }
            link
            route {
              slug {
                current
              }
            }
          }
          breadcrumb {
            self {
              en
              vi
              zh_Hans
              _type
            }
            grandparent {
              title {
                en
                vi
                zh_Hans
                _type
              }
              slug {
                current
              }
            }
            sanityParent {
              title {
                en
                vi
                zh_Hans
                _type
              }
              slug {
                current
              }
            }
          }
          heading {
            en
            vi
            zh_Hans
            _type
          }
          headingAlignment
          description {
            en
            vi
            zh_Hans
            _type
          }
          imagesExtended {
            credit
            link
            route {
              title {
                _type
                en
                vi
                zh_Hans
              }
              slug {
                current
              }
            }
            altText {
              en
              vi
              zh_Hans
              _type
            }
            image  {
              asset {
                fluid(
                  maxWidth: 800,
                  sizes: "(max-width: 600px) 100vw, 50vw"
                  ){
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          imagesFlipped
          theme
        }
        showChat
        isExcludedFromSearch
        timeline {
          items {
            heading {
              en
              vi
              zh_Hans
              _type
            }
            description {
              en
              vi
              zh_Hans
              _type
            }
            image {
              asset {
                fluid(
                  maxWidth: 1000,
                  sizes: "(max-width: 600px) 100vw, 50vw"
                  ){
                  ...GatsbySanityImageFluid
                }
              }
            }
            items {
              heading {
                en
                vi
                zh_Hans
                _type
              }
              description {
                en
                vi
                zh_Hans
                _type
              }
              image {
                asset {
                  fluid(maxWidth: 800) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              hour
              meridian
              textAlignment
              tilted
              quote {
                en
                vi
                zh_Hans
                _type
              }
              relatedStory {
                slug {
                  current
                }
                title {
                  en
                  vi
                  zh_Hans
                  _type
                }
                publishedAt
                category {
                  title {
                    en
                    vi
                    zh_Hans
                    _type
                  }
                }
                excerpt {
                  en
                  vi
                  zh_Hans
                  _type
                }
              }
            }
          }
          theme
        }
        upNext {
          theme
          items {
            cta {
              link
              route {
                slug {
                  current
                }
              }
              theme
              label {
                en
                vi
                zh_Hans
                _type
              }
            }
            description {
              en
              vi
              zh_Hans
              _type
            }
            heading {
              en
              vi
              zh_Hans
              _type
            }
            image {
              asset {
                fluid(maxWidth: 1200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(
    rawData,
    get(rawData, `sanityDailyLife.published_${intl.locale}`)
      ? intl.locale
      : 'en',
  );
  const pageData = get(data, 'sanityDailyLife', {});

  const translationContext = {
    vi: get(rawData, 'sanityDailyLife.published_vi'),
    zh_Hans: get(rawData, 'sanityDailyLife.published_zh_Hans'),
  };

  return (
    <>
      <TranslationContext.Provider value={translationContext}>
        <Navigation banner={banner} navItems={navItems} />
        <main>
          <SEO
            description={get(pageData, 'route.openGraph.description')}
            image={get(pageData, 'route.openGraph.image.asset.fixed')}
            title={pageData.route.title}
            keywords={pageData.route.openGraph?.keywords}
            noIndex={pageData.isExcludedFromSearch}
          />
          {pageData.hero && (
            <>
              <Hero {...propsTransformer(pageData.hero)} />
              <Space sizes={['small', 'regular']} />
            </>
          )}
          {pageData.timeline && (
            <>
              <Timeline {...propsTransformer(pageData.timeline)} />
              <Space sizes={['small', 'regular']} />
            </>
          )}
          {pageData.upNext && (
            <>
              <UpNext {...propsTransformer(pageData.upNext)} />
            </>
          )}
        </main>
                      <Space sizes={['xsmall', 'small']} />

        <Footer showChat={pageData.showChat} />
      </TranslationContext.Provider>
    </>
  );
}

export default DailyLifePage;
