import UnstyledImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';

export const FigCaption = styled.figcaption`
  margin-top: 8px;
  padding: 0 ${H_PADDING_MOBILE};
  text-align: left;

  ${mq.gtsm`
    margin: 16px 0 0;
    padding: 0;
  `}

  & em {
    display: inline-block;
    font-weight: ${weights.BOLD};
    margin-left: 4px;
  }
`;

export const Figure = styled.figure`
  height: 100%;
  text-align: center;

  ${mq.gtlg`
    width: ${8 / 12 * 100}%;
    margin: 0 auto;

    ${(props) =>
    props.isVerticalImage &&
    css`
        width: 100vw;
      `}
  `}
`;

export const Image = styled(UnstyledImage)`
  ${mq.gtsm`
    height: 100%;
  `}
`;

export const ImageWrapper = styled.div`
  ${mq.gtmd`
    display: inline-block;
    height: 100%;

    ${(props) => props.isVerticalImage ? css`
      ${Image},
      ${FigCaption} {
        width: 50vw;
      }
    ` : css `
      width: 100%;
    `}
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;
